import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import { Japanese } from "flatpickr/dist/l10n/ja.js"

document.addEventListener('DOMContentLoaded', function () {
  // フォーマット Y/m/d 用の flatpickr 初期化関数
  //vscodeだとdateStrなど読み込まれないと出るが消すと。todayが復活する
  function initializeFlatpickr(selector) {
    flatpickr(selector, {
      locale: Japanese,
      dateFormat: "Y/m/d",
      onReady: function(selectedDates, dateStr, instance) {
        removeTodayClass(instance);
      },
      onValueUpdate: function(selectedDates, dateStr, instance) {
        removeTodayClass(instance);
      }
    });
  }

  // today クラスを削除するための関数
  function removeTodayClass(instance) {
    setTimeout(function() {
      const todayElement = instance.calendarContainer.querySelector('.flatpickr-day.today');
      if (todayElement) {
        todayElement.classList.remove('today');
      }
    }, 0);
  }

  // 初期ロード時に適用
  initializeFlatpickr('input[type=date]');

  // Cocoonイベントで新しく追加されたフィールドにflatpickrを適用
  document.addEventListener('cocoon:after-insert', function (e) {
    setTimeout(function() {
      const newElement = e.target.closest('.nested-fields');
      if (newElement) {
        const dateFields = newElement.querySelectorAll('input[type=date]');
        dateFields.forEach(function(field) {
          initializeFlatpickr(field); // 新しいフィールドにflatpickrを適用
        });
      }
    }, 200); // CocoonのDOM操作が完了後に処理
  });

  // MutationObserverで動的に追加された要素を監視
  const observer = new MutationObserver(function (mutations) {
    mutations.forEach(function (mutation) {
      mutation.addedNodes.forEach(function (node) {
        if (node.nodeType === 1) { // 要素ノードの場合
          const dateFields = node.querySelectorAll('input[type=date]');
          dateFields.forEach(function(field) {
            initializeFlatpickr(field); // 新しく追加されたフィールドにflatpickrを適用
          });
        }
      });
    });
  });

  // 監視対象をbody全体に設定
  const targetNode = document.body;
  const config = { childList: true, subtree: true }; // 子ノードの変更を監視
  observer.observe(targetNode, config);
});
